
import { useEffect, useState } from 'react';

import { Table, Button, Flex, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, StockOutlined, } from '@ant-design/icons';

import { REQUESTS } from '../../api/requests';

import PageTemplate from '../../components/PageTemplate';

import useResize from '../../hooks/use-resize';

import ActionDropdown from '../../components/ActionDropdown';

import dateFormat from '../../utils/dateFormat';

import DnsDrawer from './components/DnsDrawer';
import ConfirmModal from '../../utils/ConfirmModal';
import Statistics from './components/Statistics';

const { confirm } = Modal;

export default function FreeDns() {
    const [data, setData] = useState([]);
    const [resellers, setResellers] = useState([]);

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);

    const [open, setOpen] = useState(false);
    const [statisticsOpen, setStatisticsOpen] = useState(false);
    const [current, setCurrent] = useState(null);

    const resize = useResize()

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                return (currentPage - 1) * limit + index + 1
            }
        },
        {
            title: "DNS",
            dataIndex: 'dns',
        },
        {
            title: "Remark",
            dataIndex: 'remark',
        },
        {
            title: "Apps Count",
            dataIndex: 'apps',
            sorter: true,
            render: (text, record) => {
                return record.apps.length
            }
        },
        {
            title: "Reseller",
            dataIndex: 'reseller_id',
            render: (text, record) => {
                let reseller = resellers.find(res => res.value == record.reseller_id)?.label;

                return reseller
            }
        },
        {
            title: "Sync Status",
            dataIndex: 'sync_status',
            render: (text, record) => {
                return <Button type='primary'
                    danger={record?.sync_status == "error"}
                    style={{ backgroundColor: record?.sync_status == "pending" ? "#e4c443" : "green" }}
                    onClick={syncStatus}
                >
                    {record?.sync_status}
                </Button>
            }
        },
        {
            title: "Created Date",
            dataIndex: 'updatedAt',
            render: (text, record) => dateFormat(text)
        },
        {
            title: "Action",
            key: 'action',
            align: "center",
            render: (text, record) => {
                return <ActionDropdown items={[
                    {
                        key: "Statistics",
                        label: <div >
                            <StockOutlined style={{ cursor: "pointer" }} /> Statistics
                        </div>,
                        onClick: (e) => handleAction(e, record)
                    },
                    {
                        key: "Edit",
                        label: <div >
                            <EditOutlined style={{ cursor: "pointer" }} /> Edit
                        </div>,
                        onClick: (e) => handleAction(e, record)
                    },
                    {
                        key: "Delete",
                        label: <div >
                            <DeleteOutlined style={{ cursor: "pointer" }} /> Delete
                        </div>,
                        onClick: (e) => handleAction(e, record)
                    },
                ]} />
            },
        },
    ];

    const deleteAction = (id) => {
        REQUESTS.FREE_DNS.DELETE({ id }, () => {
            getFreeDNS();
        })
    };

    const syncStatus = () => {
        console.log(syncStatus)
    }

    const handleAction = (e, item) => {
        switch (e.key) {
            case "Statistics":
                setCurrent(item);
                setStatisticsOpen(true);
                break;
            case "Edit":
                setCurrent(item);
                setOpen(true);
                break;
            case "Delete":
                ConfirmModal("Do you want to delete this DNS?", () => deleteAction(item.id))
                break;
            default:
                break;
        }
    }

    const handleOnChange = (pagination) => { }

    const getFreeDNS = () => {
        setLoading(true);

        REQUESTS.FREE_DNS.GET(
            (data) => {
                setData(data.rows);
                setTotal(data.count);
                setLoading(false);
            }, () => {
                setLoading(false);
            })
    }

    const getResellers = () => {
        const query = {
            pagination: false,
            filter: {
                is_partner: true,
            }
        };

        const callback = (res) => {
            let data = res.map((reseller) => {
                return {
                    value: reseller.id,
                    label: reseller.name
                }
            })
            setResellers(data)
        };

        REQUESTS.RESELLER.GET({ query: JSON.stringify(query), }, callback)
    }

    useEffect(() => {
        getResellers()
        getFreeDNS()
    }, [])

    return (
        <PageTemplate title="Free DNS">
            <Flex justify='end' style={{ marginBottom: 20 }}>
                <Button onClick={() => {
                    setOpen(true);
                }} type="primary">
                    <PlusOutlined /> Add DNS
                </Button>
            </Flex>
            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                onChange={handleOnChange}
                pagination={{
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                bordered
                scroll={{ x: 1000 }}
                size={resize <= 900 ? "small" : "large"}
            />
            <DnsDrawer
                open={open}
                onClose={() => {
                    setOpen(false);
                    if (current) setCurrent(null);
                }}
                current={current}
                getFreeDNS={getFreeDNS}
                resellers={resellers}
            />
            <Statistics
                current={current}
                open={statisticsOpen}
                onCancel={() => {
                    setStatisticsOpen();
                    if (current) setCurrent(null);
                }}
            />
        </PageTemplate>
    )
}
