import request from "./request";
import URLS from "./url";
import requestQuery from "./requestQuery";

export const REQUESTS = {
  RECAPCHA_SITE_KEY: (callback, errorCallback,) => {
    request(
      "get",
      URLS.RECAPCHA_SITE_KEY,
      {},
      callback,
      errorCallback,
    );
  },

  LOGIN: (body, callback, errorCallback) => {
    request(
      "post",
      URLS.AUTH.ADMIN_LOGIN,
      body,
      callback,
      errorCallback,
    );
  },

  APPS: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.APPS + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  PROFILE: (callback, errorCallback) => {
    request(
      "get",
      URLS.PROFILE,
      {},
      callback,
      errorCallback,
    );
  },
  RESELLER: {
    GET: (query, callback, errorCallback) => {
      request(
        "get",
        URLS.RESELLER + requestQuery(query),
        {},
        callback,
        errorCallback,
      );
    },
    CREATE: (body, callback, errorCallback) => {
      request(
        "post",
        URLS.RESELLER,
        body,
        callback,
        errorCallback,
      );
    },
    UPDATE: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.RESELLER,
        body,
        callback,
        errorCallback,
      );
    },
  },
  CREDITS: {
    ADD: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.CREDITS.ADD,
        body,
        callback,
        errorCallback,
      );
    },
    TAKE: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.CREDITS.TAKE,
        body,
        callback,
        errorCallback,
      );
    },
  },
  RESELLER_DEVICES: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.RESELLER_DEVICES + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },

  GET_VERSION: (callback, errorCallback) => {
    request(
      "get",
      URLS.VERSION,
      {},
      callback,
      errorCallback,
    );
  },
  FREE_DNS: {
    GET: (callback, errorCallback) => {
      request(
        "get",
        URLS.FREE_DNS,
        {},
        callback,
        errorCallback,
      );
    },
    ADD: (body, callback, errorCallback) => {
      request(
        "post",
        URLS.FREE_DNS,
        body,
        callback,
        errorCallback,
      );
    },
    UPDATE: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.FREE_DNS,
        body,
        callback,
        errorCallback,
      );
    },
    DELETE: (body, callback, errorCallback) => {
      request(
        "delete",
        URLS.FREE_DNS,
        body,
        callback,
        errorCallback,
      );
    },
  },
  FREE_DNS_STATISTICS: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.FREE_DNS_STATISTICS + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  FREE_DNS_STATISTICS_ALL: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.FREE_DNS_STATISTICS_ALL + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
};
