import { useEffect, useState } from 'react';
import { Modal, DatePicker, Table } from 'antd'
import { REQUESTS } from '../../../api/requests';
import useStore from '../../../hooks/use-store';

const { RangePicker } = DatePicker;

export default function Statistics({ current, open, onCancel, }) {
    const [data, setData] = useState([]);
    const [year, setYear] = useState(2024);
    const [month, setMonth] = useState(5);

    const [apps] = useStore('apps');

    const platforms = ["tizen", "webos", "android", "vidaa", "foxxum", "zeasn", "vizio", "tvos", "ios", "roku"];

    const getStatistics = () => {
        const query = {
            id: current.id,
            // id: 34,
            year: year,
            month: month,
        }

        if (year && month) {
            query.year = year;
            query.month = month;
        }

        REQUESTS.FREE_DNS_STATISTICS(query, (res) => {
            console.log(res)

            const appIds = Object.keys(res);
            const appsData = appIds.map(id => {
                const app = apps.find(app => app.id === parseInt(id));
                const appData = { id: app.id, name: app.name };
                res[id]?.message.forEach(item => {
                    appData[item.model] = item.device_count;
                });
                return appData;
            });

            setData(appsData);
            console.log('appsData', appsData)
        }, (error) => { })
    }

    const availablePlatforms = platforms.filter(platform =>
        data.some(item => item.hasOwnProperty(platform))
    );

    const columns = [
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
        },
        ...availablePlatforms.map(platform => ({
            title: platform ? platform[0].toUpperCase() + platform.substring(1) : '',
            dataIndex: platform,
            key: platform,
        })),
    ]

    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    useEffect(() => {

    }, [open])

    useEffect(() => {
        if (current) {
            getStatistics()
        }
    }, [current]);

    return (
        <Modal
            open={open}
            destroyOnClose={true}
            onCancel={onCancel}
        >
            <div>
                <h1>Free DNS Statistic</h1>
                <DatePicker onChange={onChange} picker="year" />
                <DatePicker onChange={onChange} picker="month" />
            </div>
            <p>{current?.dns}</p>

            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                summary={pageData => {
                    const totals = availablePlatforms.reduce((acc, platform) => {
                        acc[platform] = 0;
                        return acc;
                    }, {});

                    pageData.forEach(dataItem => {
                        availablePlatforms.forEach(platform => {
                            totals[platform] += dataItem[platform] || 0;
                        });
                    });

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell>Total</Table.Summary.Cell>
                                {availablePlatforms.map(platform => (
                                    <Table.Summary.Cell key={platform}>
                                        {totals[platform]}
                                    </Table.Summary.Cell>
                                ))}
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={columns.length}>
                                    Total {Object.values(totals).reduce((sum, val) => sum + val, 0)}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </Modal>
    )
}
