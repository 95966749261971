import { useEffect, useState } from 'react';

import { Drawer, Form, Input, Button, Flex, Checkbox, InputNumber } from 'antd';

import { REQUESTS } from '../../../api/requests';

import Message from '../../../components/Message';

import passwordGenerator from '../../../utils/passwordGenerator';

export default function ResellerDrawer({ onClose, open, current, getResellers }) {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState({
        type: false,
        text: ""
    });

    const onFinish = (values) => {
        const { name, email, password, confirm_password, blocked, remark, is_partner, free_dns_price } = values;

        setMessage({
            type: false,
            text: ""
        });

        setLoading(true);

        if (current) {
            const body = {
                id: current.id,
                name: name.trim(),
                blocked,
                remark,
                email,
                is_partner,
                free_dns_price
            }

            if (password || confirm_password) {
                body["password"] = password;
                body["confirm_password"] = confirm_password;
            }

            REQUESTS.RESELLER.UPDATE(
                body,
                (data) => {
                    setLoading(false)
                    onClose()
                    getResellers();
                },
                (err) => {
                    setLoading(false)
                    setMessage({
                        type: false,
                        text: err
                    })
                })
        } else {

            const body = {
                name: name.trim(),
                email,
                password,
                confirm_password,
                is_partner,
                free_dns_price
            }

            if (remark) body.remark = remark;

            REQUESTS.RESELLER.CREATE(
                body,
                (data) => {
                    setLoading(false)
                    getResellers();
                    onClose()
                },
                (err) => {
                    setMessage({
                        type: false,
                        text: err
                    })
                    setLoading(false)
                })
        }
    }

    const randomPasswordGenerator = () => {
        let password = passwordGenerator();

        form.setFieldsValue({
            password: password,
            confirm_password: password
        })
    }

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setMessage({
                type: false,
                text: ""
            })
        }
    }, [open])

    useEffect(() => {
        if (current) {
            form.setFields([
                {
                    name: "name",
                    value: current.name
                },
                {
                    name: "email",
                    value: current.email
                },
                {
                    name: "blocked",
                    value: current.blocked
                },
                {
                    name: "remark",
                    value: current.remark || ""
                },
                {
                    name: "blocked",
                    value: current.blocked
                },
                {
                    name: "free_dns_price",
                    value: current.free_dns_price
                },
                {
                    name: "is_partner",
                    value: current.is_partner
                },
            ])
        }
    }, [current])

    return (
        <Drawer
            title={current ? "Edit Reseller" : "Create Reseller"}
            onClose={onClose}
            open={open}
            destroyOnClose={true}
        >
            <Form
                form={form}
                name="basic"
                style={{
                    maxWidth: 600,
                }}
                autoComplete="off"
                layout='vertical'
                onFinish={onFinish}
                initialValues={{
                    name: "",
                    email: "",
                    password: "",
                    remark: ""
                }}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Name is required!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: true,
                            message: 'Email is required!',
                        },
                        {
                            type: "email",
                            message: "Please enter valid email!"
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="remark"
                    label="Remark"
                    rules={[
                        {
                            max: 255,
                            message: "Remark must be at least 255 characters"
                        }
                    ]}
                >
                    <Input.TextArea style={{ resize: "none" }} />
                </Form.Item>

                <Form.Item
                    name="free_dns_price"
                    label="Free DNS Price"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: !current,
                            message: "Password is required!"
                        },
                        {
                            min: 8,
                            message: "Password must be at least 8 characters!"
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="Confirm Password"
                    name="confirm_password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: !current,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Button
                    onClick={randomPasswordGenerator}
                    style={{ marginBottom: 10 }}
                >
                    Generate a Password
                </Button>
                {
                    current &&
                    <Form.Item
                        name="blocked"
                        valuePropName="checked"
                    >
                        <Checkbox>Block</Checkbox>
                    </Form.Item>
                }
                <Form.Item
                    name="is_partner"
                    valuePropName="checked"
                    style={{ margin: 0 }}
                >
                    <Checkbox>Is Partner</Checkbox>
                </Form.Item>
                <Flex justify='center'>
                    <Message message={message} />
                </Flex>
                <Form.Item >
                    <Flex justify="center">
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                        >
                            Save
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
