import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  UsergroupAddOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CloseOutlined,
  LogoutOutlined, HddOutlined
} from '@ant-design/icons';

import { Layout, Menu, theme, Button, Flex, Modal } from 'antd';

import useStore from '../hooks/use-store';

import { REQUESTS } from '../api/requests';

import ICONS from '../config/icons';

import ConfirmModal from '../utils/ConfirmModal';
import styles from "./layout.module.scss"

const { Header, Content, Sider, } = Layout;

const version = require('../version.json').version;

export default function AdminLayout() {
  const location = useLocation();

  const navigate = useNavigate()

  const [collapsed, setCollapsed] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  const [store, setStore] = useStore("profile")
  const [apps, setAppsStore] = useStore("apps")

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const logout = () => {
    setCollapsed(true);
    ConfirmModal("Do you want to Logout?", () => {
      localStorage.clear();
      navigate("/");
    })
  }

  const getApps = () => {
    let query = {
      pagination: false,
    };
    REQUESTS.APPS({ query: JSON.stringify(query) },
      (data) => {
        setAppsStore({ type: "apps/setApps", payload: data })
      }, (err) => {
        console.error(err);
      });
  }

  const getProfile = () => {
    REQUESTS.PROFILE(
      (data) => {
        setStore({ type: "profile/setProfile", payload: data })
      },
      (error) => {
        navigate("/")
      });
  }

  useEffect(() => {
    getProfile();
    getApps();

    setWidth(window.innerWidth);

    if (window.innerWidth < 900) {
      setCollapsed(true)
    } else {
      setCollapsed(false)
    }

    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);

      if (window.innerWidth < 900) {
        setCollapsed(true)
      } else {
        setCollapsed(false)
      }
    })
  }, [])

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed} style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        padding: "10px 0px",
        transition: "all 0.2s",
        transform: width < 900 ? !collapsed ? "translateX(0px)" : "translateX(-100%)" : "translateX(0px)",
        zIndex: width < 900 ? 999999 : 0,
        background: "#22272a"
      }}>

        <Flex justify='space-between' gap={10} vertical style={{ minHeight: "100%" }}>
          <div>
            {width < 900 &&
              <CloseOutlined
                onClick={() => setCollapsed(true)}
                className={styles['close-icon']}
              />}
            <div className="demo-logo" style={{ width: collapsed ? 59 : 107 }}
              onClick={() => navigate("/admin/dashboard/resellers")}>
              {collapsed ? ICONS.LOGO_ICON : ICONS.LOGO}
            </div>
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["/admin/dashboard/resellers"]}
              selectedKeys={[location.pathname]}
              items={[
                {
                  key: "/admin/dashboard/resellers",
                  icon: <UsergroupAddOutlined />,
                  label: <Link to={"/admin/dashboard/resellers"}>Resellers</Link>,
                },
                {
                  key: "/admin/dashboard/free-dns",
                  icon: <HddOutlined />,
                  label: <Link to={"/admin/dashboard/free-dns"}>Free DNS</Link>,
                },
              ]}
              onClick={() => {
                if (width < 900) {
                  setCollapsed(true)
                }
              }}
              style={{ background: "transparent", marginTop: 35 }}
            />
          </div>
          <div>
            <div onClick={logout} className={styles['logout-button']}>
              <LogoutOutlined className={styles['logout-icon']} />
              {!collapsed ? "Logout" : ""}
            </div>
            <div className={styles["version"]}>
              {collapsed ? null : `Admin v${version}`}
            </div>
          </div>
        </Flex>
      </Sider>
      <Layout style={{ marginLeft: width < 900 ? 0 : collapsed ? 80 : 200, transition: "all 0.2s" }}>
        <Header
          style={{
            padding: "10px 20px 10px 10px",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: "#ffffff",
            boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)",
            backdropFilter: "blur(10px)",
            zIndex: 99,
            position: "fixed",
            top: 0,
            right: 0,
            left: width < 900 ? 0 : collapsed ? 80 : 200,
            transition: "all 0.2s",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
          />
        </Header>
        <Content
          style={{
            margin: collapsed ? "70px 6px" : '88px 16px',
            padding: collapsed ? 10 : 24,
            minHeight: "100vh",
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
          id='ant-layout-content'
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout >
  );
};