import { HOST } from "../config";

export const URLS = {
    VERSION: `${HOST}version`,
    AUTH: {
        RESELLER_LOGIN: `${HOST}reseller/login`,
        ADMIN_LOGIN: `${HOST}admin/login`,
    },
    PROFILE: `${HOST}admin/profile`,
    RESELLER: `${HOST}admin/reseller`,
    CREDITS: {
        ADD: `${HOST}admin/reseller/add/credit`,
        TAKE: `${HOST}admin/reseller/take/credit`,
    },
    RESELLER_DEVICES: `${HOST}admin/devices`,
    APPS: `${HOST}admin/apps`,
    FREE_DNS: `${HOST}admin/free_dns`,
    FREE_DNS_STATISTICS_ALL: `${HOST}admin/free_dns/statistics/all`,
    FREE_DNS_STATISTICS: `${HOST}admin/free_dns/statistics`,
};

export default URLS;
